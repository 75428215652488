// pull in application variables
@import '_variables.scss';

.png-customer-form-vehicle {
  /* offset the background */
  background: #f2efef;
  border-radius: 10px;

  /* pad the content */
  padding: 4px 12px 0 12px;

  /* create some space between them */
  margin-bottom: 6px;
}

.png-customer-form-vehicle {
  display: block;
}

.png-customer-form-vehicle-name {
  // don't wrap
  white-space: nowrap;
}

.png-customer-form-vehicle-remove {
  color: $red;
  text-align: right;
  padding-top: 2px;
}

.png-customer-form {
  /* buttons */
  .btn {
    margin-top: 10px;
  }
}

/* mobile overrides */
@media screen and (max-width: 576px) {
  .png-customer-form {
    /* buttons */
    .btn {
      padding: 15px 20px;
    }
  }
}
