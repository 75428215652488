// sticky element
#sticky {
  transition: all 0.3s ease;
}

// placeholder while sticky element is stuck
#placeholder {
  display: none;
}

// stick an element to the top of the screen
.sticky {
  // constrain the width
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;

  // fix to the top
  position: fixed !important;
  top: 0;

  // center
  left: 0 !important;
  right: 0 !important;

  // keep on top
  z-index: 1000;

  // it's on top, so no top rounded corners
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  .card-header {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  // darker border
  border: 1px solid darkgray !important;

  // it's on top, so no top border
  border-top: 0 !important;

  // small screens are a little different
  @media screen and (max-width: 880px) {
    // full width
    width: 100%;
    max-width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;

    // when filling the width, nix bottom rounded corners
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    // when filling the screen, nix top, left, and right borders
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

// picture
.png-bookbox-picture {
  // offset the image from the top
  margin-top: 10px;

  img {
    // round the corners
    border-radius: 0.25rem;
  }
}
