// pull in application variables
@import '_variables.scss';

/* Oauth login */
.png-oauth {
  margin-bottom: 4px;
}

.png-login-forgot-fp {
  margin-top: 2px;
  width: 100%;
  text-align: center;
  font-size: smaller;
}
