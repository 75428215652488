// variables
@import '_variables.scss';

// mixins
@import '_mixins.scss';

// the rest of Bootstrap (at least the parts we use)
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
//@import 'node_modules/bootstrap/scss/images';
//@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/nav';
//@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
//@import 'node_modules/bootstrap/scss/breadcrumb';
//@import 'node_modules/bootstrap/scss/pagination';
//@import 'node_modules/bootstrap/scss/badge';
//@import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
//@import 'node_modules/bootstrap/scss/media';
//@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
//@import 'node_modules/bootstrap/scss/popover';
//@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/print';

html {
  /* apply a natural box layout model to all elements, but allowing components to change */
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  /* site background color */
  background-color: $white;

  /* black text by default */
  color: #000;

  /* Roc Grotesk, falling back to system fonts */
  font-family: roc-grotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  /* let components set their own margins */
  margin: 0px;

  /* enforce a reasonable minimum */
  min-width: 320px;

  /* base font */
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

/* for our sticky footer */
body,
wrapper {
  min-height: 100vh;
}

/* headers */
h1 {
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 700;
}
h2 {
  font-size: 2rem;
  line-height: 1.1875;
  font-weight: 600;
}
h3 {
  font-size: 1.55rem;
  line-height: 1.25;
  font-weight: 600;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.33;
  font-weight: 500;
}

/* PNG color */
.png-background {
  background-color: $yellow;

  /* button changes when using the PNG color */
  .btn-primary {
    &:hover {
      background: $white;
    }
    &:focus {
      background: $white;
      border-color: $white;
    }
  }
}

/* main content */
.png-main {
  /* sit below the header */
  padding-top: 55px;

  /* sit above the footer and the Live Agent button */
  padding-bottom: 75px;

  /* get off of the edges */
  padding-left: 5px;
  padding-right: 5px;

  /* constrain the width; it looks too sparse on large screens otherwise */
  max-width: 900px;

  /* enforce a minimum height to not crowd the ribbon */
  min-height: 450px;

  /* center it */
  margin-left: auto;
  margin-right: auto;
}

/* make sure we can reliably layer things while modals are displayed */
.modal {
  z-index: 1400;
}
.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
  z-index: 1300 !important;
}

body.modal-open {
  // keep the scrollbar state when launching modal
  overflow-y: scroll !important;
}

/* buttons */
.btn {
  font-size: 16px;
  font-weight: 600;
  min-width: 180px;
  padding: 15px 63px;

  /* bootstrap rounds corners by default */
  border-radius: 0px !important;

  /* disabled */
  &:disabled {
    cursor: not-allowed;
  }
}
.btn-primary {
  background: $black;
  color: $white;
  border-color: $black;
  text-align: center;
  &:hover {
    background: $yellow;
  }
  &:focus {
    background: $yellow;
    outline: 2px solid $yellow;
    outline-offset: 4px;
    border-color: $yellow;

    /* disable the box shadow on focus */
    box-shadow: none;
  }
}
.btn-primary:disabled {
  background: $gray;
  border-color: $gray;
}
.btn-secondary {
  background: $white;
  color: $black;
  border-color: $black;
  text-align: center;
  &:hover {
    background: $yellow;
    border-color: $yellow;
  }
  &:focus {
    background: $black;
    outline: 2px solid $black;
    outline-offset: 4px;
    border-color: $black;

    /* disable the box shadow on focus */
    box-shadow: none;
  }
}
.btn-secondary:disabled {
  background: $gray;
  border-color: $gray;
}

/* some animations */
.png-half-spin:hover {
  transform: rotate(180deg);
}
.png-half-spin {
  transition: transform 0.5s ease-in-out;

  /* see https://stackoverflow.com/questions/14729492/css3-transform-rotate-causing-1px-shift-in-chrome */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/* don't pad modals so much */
.modal-body {
  padding: 6px 4px 6px 4px;
}

/* inline links */
.png-inline-link {
  font-weight: $bold;
  cursor: pointer;
}

/* other clickable things */
.png-clickable {
  cursor: pointer;
}

/* huge things */
.png-huge {
  font-size: 5rem;
}

/* this is misnamed; it's really a section header */
.png-page-header {
  // we want to match <h1> styling
  font-size: 2.25rem;
  line-height: 1.25;
  font-weight: 700;
}

/* outdated browser warning */
.png-outdated {
  /* sit on top of everything */
  z-index: 5000;
}
#outdated .last {
  top: 4px;
  right: 4px;
}

/* a hidden item */
.png-hidden {
  opacity: 0;
}

/* reCAPTCHA */
.grecaptcha-badge {
  bottom: 26px !important;
}

/* flexbox addition */
.flex-grow {
  flex: 1 0 auto;
}

/* progress bar */
.progress-bar {
  background-color: $yellow;
}

/* links */
a {
  color: $blue;
}

/* photos */
.png-photo {
  // look and feel
  border-radius: 0.25rem;
  margin-top: 10px;
  margin-bottom: 20px;

  // this will force the image to take the size of it's parent <div>
  width: 100%;
}

/* mobile overrides */
@media screen and (max-width: 680px) {
  /* main content */
  .png-main {
    /* no header */
    padding-top: 5px;
  }
}

/* mobile overrides; these should match the *-small settings */
@media screen and (max-width: 425px) {
  /* main content */
  .png-main {
    /* no header */
    padding-top: 5px;
  }

  /* buttons */
  .btn {
    min-width: 120px;
  }
}

/* mobile overrides */
@media screen and (max-width: 375px) {
  /* buttons */
  .btn {
    min-width: 80px;
    padding: 15px 20px;
  }
}
