// pull in application variables
@import '_variables.scss';

.png-reservation-details-section {
  margin-bottom: 10px;
}

.png-reservation-details-section-header {
  color: $yellow;
  font-weight: $bold;
  font-size: 1.5em;
}

.png-reservation-details-operator {
  text-align: center;
}

.png-reservation-details-notes {
  margin-top: 4px;
  font-size: smaller;
}

.png-reservation-details-fp {
  margin-top: 4px;
}

.png-reservation-details-name {
  font-weight: bolder;
}
