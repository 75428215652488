.png-bookbox-dates {
  /* make the input look not readonly */
  background-color: #fff !important;
}

.png-reservation-book-box-disclaimer {
  padding: 12px 0 0 0 !important;
}

#bookBoxForm {
  // transition nicely
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9 {
    transition: all 0.3s ease;
  }

  // cut down the padding
  @media screen and (max-width: 880px) {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9 {
      padding-left: 2px;
      padding-right: 2px;
    }
  }

  // handle overflow of locations
  #facilityId {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // next button
  .png-reservation-book-next {
    // make the button full width
    min-width: 0;
    width: 100%;

    // no margin or padding
    margin: 0;
    padding: 0;

    // center the icon
    display: flex;
    align-items: center;
    justify-content: center;

    // bump up the font size
    .fa-arrow-right {
      font-size: 1.25rem;
    }

    // make it stand out from the background
    border: #000 solid 1px;
  }
}
