.png-reservation-view-cancel-confirm {
  border: 1px solid black;
  border-radius: 6px;
  padding: 15px;
}

.react-confirm-alert-overlay {
  margin-left: 5px;
  margin-right: 5px;
  z-index: 1900;
}

.react-confirm-alert {
  /* buttons */
  .btn {
    margin-top: 0;
    white-space: nowrap;
  }
}

/* mobile overrides */
@media screen and (max-width: 425px) {
  .react-confirm-alert {
    /* buttons */
    .btn {
      min-width: 100px;
      padding: 15px 20px;
    }
  }
}
