// pull in application variables
@import '_variables.scss';

.png-location-details {
  /* bottom padding */
  padding-bottom: 30px;
}

.png-locations-details-name a {
  color: $black;
}

.png-location-info {
  /* no padding */
  padding-left: 0px !important;
  padding-right: 0px !important;

  /* link */
  a {
    color: $black;
    &:hover {
      color: $yellow;
    }
  }

  /* icons */
  .png-location-icons {
    /* offset */
    margin-top: 12px;

    /* look and feel */
    font-size: smaller;
  }

  /* buttons */
  .png-location-buttons {
    .btn {
      min-width: 0;
      width: 100%;
      padding: 5px 20px;
      margin-top: 0;
    }

    /* buttons */
    .png-location-button {
      /* shrink the top margin */
      margin-top: 12px;

      /* introduce a bottom margin */
      margin-bottom: 10px;
    }
    .png-location-button:first-child {
      padding-right: 3px !important;
    }
    .png-location-button:last-child {
      padding-left: 3px !important;
    }
    .png-location-button:only-child {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.png-location-info-about {
  /* offset */
  margin-top: 12px;
}

.png-locations-map-label-primary {
  font-weight: $bold;
  font-size: 0.9rem;
}

.png-locations-map-label-poi {
  /* look and feel */
  background-color: lightyellow;
  border: 1px black solid;
  border-radius: 3px;
  padding: 3px;
  font-weight: $bold;
  font-size: 0.8rem;
  max-width: 200px;
}
