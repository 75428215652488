// pull in application variables
@import '_variables.scss';

/* ribbon */
.png-ribbon {
  /* fixed to the left side and set off the top by the header plus the close container height */
  position: fixed;
  left: 0px;
  top: calc(50px + 50px);

  /* look and feel */
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  /* this needs to match the number of icons * the height of primary navigation items */
  height: calc(5 * 60px);

  /* this should match the flyout's left padding less 5px */
  width: calc(40px - 5px);

  /* stay on top of the flyout but below the modal overlay */
  z-index: 1200;

  /* animation magic for changes */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

/* small ribbon */
.png-ribbon-small {
  /* fixed to the left side and set off the top by the header plus the close container height */
  top: calc(30px + 50px);
}

/* ribbon item */
.png-ribbon-item {
  /* this should match the height of the flyout's primary items */
  height: 60px;

  /* offset them */
  color: $white;

  /* this should match the look and feel of the flyout's primary item */
  font-size: 1.5rem;
  font-weight: $bold;
  white-space: nowrap;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial,
    sans-serif;

  /* this should match the ribbon's width */
  width: inherit;

  /* allow vertical alignment */
  display: table;
}
.png-ribbon-item a {
  color: $white;
}
.png-ribbon-item-icon,
.png-ribbon-item-icon-active {
  /* vertically center */
  display: table-cell;
  vertical-align: middle;

  /* horizontally center */
  text-align: center;

  /* it's clickable */
  cursor: pointer;
}
.png-ribbon-item-icon-selected {
  color: $black;
}

/* icon while logged in */
.png-ribbon-item-icon-active {
  filter: drop-shadow(0 0 4px black);
}

/* hide the ribbon on small screens */
@media screen and (max-width: 950px) {
  .png-ribbon {
    display: none;
  }
}

/* close menu container */
.png-menu-close-container {
  /* this needs to jive with the ribbon */
  height: 50px;

  /* back it up into the margin; this should match the padding-left of .bm-menu */
  margin-left: -40px;

  /* allow sizing of bars */
  display: table;
}

/* close icon */
.png-menu-close-icon {
  /* vertically center */
  display: table-cell;
  vertical-align: middle;

  /* this should match the ribbon width */
  width: calc(40px - 5px);

  /* clickable */
  cursor: pointer;
}
.png-menu-close-bar1,
.png-menu-close-bar2 {
  /* bar size */
  width: 30px;
  height: 5px;

  /* look and feel */
  background-color: $black;
  border-radius: 2px;

  /* space between bars; note that the margins overlap, so this value is not doubled */
  margin: 6px 0;

  /* animation magic for changes */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}
.png-menu-close-bar1 {
  /* slanty */
  -webkit-transform: rotate(-45deg) translate(-2px, 4px);
  transform: rotate(-45deg) translate(-2px, 4px);
}
.png-menu-close-bar2 {
  /* slanty */
  -webkit-transform: rotate(45deg) translate(-4px, -6px);
  transform: rotate(45deg) translate(-4px, -6px);
}

/* primary items */
.png-menu-primary {
  /* these need to be predictable to match the ribbon */
  height: 60px;

  /* look and feel */
  font-size: 1.5rem;
  white-space: nowrap;
  font-family: roc-grotesk, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans',
    Arial, sans-serif;

  /* allow vertical alignment */
  display: table;
}
.png-menu-primary-text {
  padding-top: 4px;
  a {
    color: $black;
  }
}
.png-menu-primary-togglesubs {
  /* look and feel */
  color: $black;
  font-size: 0.8em;

  /* clickable */
  cursor: pointer;

  /* display inline and offset */
  margin-left: 8px;
  display: inline-block;
}
.png-menu-primary-subs {
  margin-top: -12px;
}
.png-menu-primary-sub {
  /* look and feel */
  font-size: 1.2rem;
  font-weight: 200;
  white-space: nowrap;
  font-family: roc-grotesk, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans',
    Arial, sans-serif;

  /* indent slightly */
  margin-left: 20px;
}
.png-menu-primary-sub-text a {
  color: $black;
}

/** More Locations */
.png-menu-more-locations {
  .png-menu-primary-sub {
    color: $black;

    .png-menu-primary-sub-text {
      /* indent a little more */
      margin-left: 20px;
    }
  }
}

/* FPP */
.png-menu-fpp {
  /* we need to simulate a link */
  color: $black;
  cursor: pointer;
}
.png-menu-fpp:hover {
  /* we need to simulate a link */
  text-decoration: underline;
}

/* secondary items */
.png-menu-secondary {
  font-size: 1.2rem;
  height: 35px;
  white-space: nowrap;
}
.png-menu-secondary a {
  color: $black;
}

/* center the items */
.png-menu-primary-text,
.png-menu-secondary-text {
  /* vertically center */
  display: table-cell;
  vertical-align: middle;
}

/* hide burger button */
.bm-burger-button,
.bm-cross-button {
  display: none;
  width: 0px;
  height: 0px;
}

/* invisible container that holds the menu */
.bm-menu-wrap {
  /* position below header; this value must match header height */
  top: 50px;
}

/* invisible container that holds the menu */
.bm-menu-wrap-small {
  /* position below header; this value must match header height */
  top: 30px;
}

/* menu container */
.bm-menu {
  /* look and feel */
  background: $yellow;

  /* keep things off of the right-hand border */
  padding-right: 20px;

  /* set it off the side by the same amount as the ribbon */
  padding-left: 40px;
}

/* menu item */
.bm-item {
  /* no outline */
  outline: none;
}

/* menu item list */
.bm-item-list {
  /* look and feel */
  color: #b8b7ad;
}

/* menu overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* mobile overrides; these should match the *-small settings */
@media screen and (max-width: 425px) {
  /* invisible container that holds the menu */
  .bm-menu-wrap {
    /* position below header; this value must match header height */
    top: 30px;
  }
}
