// pull in application variables
@import '_variables.scss';

.png-reservation-book-summary {
  /* look and feel */
  background-color: lightyellow;
  border: 1px solid #e9ecef; /* should match .modal-header's bottom border */
  border-radius: 3px;

  /* give it breathing room */
  padding-top: 4px;
  padding-bottom: 4px;
}
.png-reservation-book-summary-name {
  /* this should stay out of the way */
  font-size: 0.7rem;
  font-weight: bolder;
}
.png-reservation-book-summary-value {
  /* this should stay out of the way */
  font-size: 0.75rem;
}

.png-reservation-book-disclaimer {
  padding: 0 0 12px 0 !important;
}

.png-reservation-book-login {
  /* should match the top padding for .col-form-label-lg */
  margin-top: calc(0.5rem + 1px);

  /* don't set it off so far from the content */
  margin-bottom: 6px;
}

.png-reservation-book-progress {
  height: 2px !important;
}
.png-reservation-book-progress .progress-bar {
  background-color: $blue;
}

/* reservation form */
.png-reservation-book-form {
  /* buttons */
  .png-reservation-buttons {
    .btn {
      margin-top: 20px;
    }

    .png-reservation-button:first-child {
      text-align: left !important;
    }
    .png-reservation-button:last-child {
      text-align: right !important;
    }
    .png-reservation-button:only-child {
      text-align: right !important;
    }
  }
}

/* mobile overrides */
@media screen and (max-width: 576px) {
  .png-reservation-book-form {
    /* buttons */
    .png-reservation-buttons {
      .btn {
        min-width: 100px;
        padding: 15px 20px;
        margin-top: 20px;
      }
    }
  }
}

/* mobile overrides */
@media screen and (max-width: 375px) {
  .png-reservation-book-form {
    /* buttons */
    .png-reservation-buttons {
      .btn {
        min-width: 80px;
      }
    }
  }
}
