// pull in variables
@import '_variables.scss';

// sticky, on top footer
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 900;
}

// page footer
.png-footer {
  // fill the available width
  width: 100%;

  // look and feel
  font-size: $font-size-sm;

  // the footer container will establish padding
  padding-left: 0px;
  padding-right: 0px;

  // make sure it stays on top when not on mobile
  z-index: 900;
}

// footer container
.png-footer-container {
  // make sure content is positioned relative to this container
  position: relative;

  // pad off the edges
  padding-left: 5px;
  padding-right: 5px;

  // center it
  margin-left: auto;
  margin-right: auto;
}
