// pull in application variables
@import '_variables.scss';

.loader {
  height: 30px;
  width: 250px;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  background-color: $black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: $green;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: $purple;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: $red;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: $orange;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: $blue;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: $yellow;
  animation-delay: 0s;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(215px);
  }
  65% {
    transform: translateX(215px);
  }
  95% {
    transform: translateX(0);
  }
}
