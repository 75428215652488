// pull in application variables
@import '_variables.scss';

.daterangepicker {
  .png-calendar-container {
    .png-calendar-hint {
      padding-top: 12px;
      text-align: center;
      color: gray;
    }
  }

  select {
    font-size: 18px !important;
  }

  .calendar-table {
    select {
      background: inherit;
      border: 1px solid #ced4da !important;
      margin-top: 6px;
      margin-bottom: 6px;
      width: inherit;
    }

    thead {
      th {
        font-size: 14px;
        cursor: text;
        margin-bottom: 10px;

        &.available {
          cursor: pointer;
        }
      }
    }

    td {
      font-size: 16px;
      min-width: 36px;
      width: 36px;
      padding: 4px;
    }
  }

  // this is purposely outside of .calendar-table
  td {
    &.in-range {
      background-color: #f6e895;
    }

    &.active {
      background-color: $yellow;

      &:hover {
        background-color: $yellow;
      }
    }
  }

  .calendar-time {
    select {
      background: inherit;
      border: 1px solid #ced4da;
      margin-top: 6px;
      margin-bottom: 6px;
      width: inherit;
      font-size: small !important;
    }
  }

  .drp-selected {
    display: none;
  }

  .btn {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 14px !important;
  }
}
