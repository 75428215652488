// variables
@import '_variables.scss';

/* form tweaks */
form {
  /* fill the available space */
  width: 100%;

  /* don't crowd the border */
  padding-left: 6px;
  padding-right: 6px;
}
.form-row > .png-form-more {
  margin-bottom: 0.2rem;
}
.form-row h3 {
  margin-top: 6px;
  margin-bottom: 0px;
}
form .png-form-error {
  /* look and feel */
  background: $error;
  border-radius: 4px;
  color: $white;
  padding: 4px;
  font-size: 1.2rem;

  /* break the text */
  display: block;

  /* pad it a bit */
  margin-bottom: 10px;
}
.col-form-label {
  padding-bottom: 0px !important;
}
.png-form {
  /* pad the content off the top a tad... */
  padding-top: 8px;

  /* ... but not on the sides */
  padding-left: 0px;
  padding-right: 0px;
}

/* form input tweaks */
input[type='checkbox']:focus,
input[type='radio']:focus {
  /* disable the box shadow on focus */
  box-shadow: none;
}
input[type='radio']:invalid {
  /* disable the box shadow on invalid */
  box-shadow: none;
}
.png-field-error {
  /* make the error stand out; this should match png-form-error */
  color: $error;
  font-size: 1rem;
}
.png-field-warning {
  /* make the warning stand out */
  color: $orange;
  font-size: 1rem;
}
input:invalid {
  /* highlight invalid fields */
  box-shadow: 0;
  border-left: 5px solid $error;
}
input:valid[required] {
  /* highlight valid fields */
  box-shadow: 0;
  border-left: 5px solid #42a948;
}
select:invalid {
  /* highlight invalid fields */
  box-shadow: 0;
  border-left: 5px solid $error;
}
select:valid[required] {
  /* highlight valid fields */
  box-shadow: 0;
  border-left: 5px solid #42a948;
}

/* embed icons in a large input field */
.input-lg-embedded-right {
  /* send them to the right */
  float: right;

  /* set them off of the right edge */
  margin-right: 6px;

  /* they would normally fall under the input; raise them up */
  margin-top: -44px;
  position: relative;

  /* make sure they sit on top of the input */
  z-index: 300;
}

/* tooltips */
.png-tooltip {
  /* clickable */
  cursor: pointer;

  /* wrap words */
  word-wrap: break-word;
  white-space: normal;
}
.png-tooltip .png-tooltip-text {
  /* hidden by default */
  visibility: hidden;

  /* limit the width */
  max-width: 200px;

  /* look and feel */
  background-color: $black;
  color: #fff;
  text-align: right;
  border-radius: 6px;
  padding: 5px;

  /* position just to the left of the parent */
  position: absolute;
  z-index: 300;
  right: 10%;
}
.png-tooltip:hover .png-tooltip-text {
  /* show on hover */
  visibility: visible;
}

/* form buttons */
.btn {
  /* space it away from the form a tad */
  margin-top: 2rem;
}

/* group of radio buttons */
.png-radio-group {
  /* indent slightly */
  margin-left: 1rem;
}

/* embed icons in a large input field */
.input-lg-embedded-right {
  /* send them to the right */
  float: right;

  /* set them off of the right edge */
  margin-right: 6px;

  /* size them the same as the input */
  font-size: 1.25rem;

  /* they would normally fall under the input; raise them up */
  margin-top: -2.2rem;
  position: relative;

  /* make sure they sit on top of the input */
  z-index: 300;
}

/* lighten placeholder color */
.form-control::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: lightgray;
}
.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: lightgray;
}
.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: lightgray;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: lightgray;
}
.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: lightgray;
}
