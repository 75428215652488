// pull in application variables
@import '_variables.scss';

// alerts
.png-message-box {
  font-size: $font-size-md;

  // don't overflow small screens
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

// not so much padding
.png-message-box.container-fluid {
  padding: 0 !important;
}

// no extra padding for last embedded paragraph
.png-message-box > div > div > span > p:last-child {
  margin-bottom: 0;
}

// links don't stand out well enough in "info" box
.png-message-box a,
.png-message-box .png-link,
.png-message-box .png-inline-link {
  color: rgb(0, 0, 173);
}
.png-message-box a:hover,
.png-message-box .png-link:hover,
.png-message-box .png-inline-link:hover {
  color: #cca93f;
}
