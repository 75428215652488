// variables
@import '_variables.scss';

.png-reservation-book-payment-card-preview {
  /* should match the top padding for .col-form-label-lg */
  padding-top: calc(0.5rem + 1px);
}

/* tweak the card size */
.rccs,
.rccs__card {
  width: 270px;
  height: 170px;
}

.png-reservation-book-payment-type {
  font-size: 1.5rem;
}

/* Apple Pay button */
@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    width: 160px;
    height: 40px;
    cursor: pointer;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }

  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    width: 160px;
    height: 40px;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: $white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: $white;
    border: 0.5px solid black;
  }
}
