// pull in application variables
@import '_variables.scss';

// picture
.png-shuttles-picture {
  // offset the image from the top
  margin-top: 10px;

  img {
    // round the corners
    border-radius: 0.25rem;
  }
}
