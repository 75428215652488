// PNG's colors
$white: white;
$black: black;
$gray: #e0e0e0;
$red: #f40000;
$yellow: #e6cb28;
$lightyellow: #fff095;
$green: #6fb640;
$blue: #007bff;
$orange: #ed9e28;
$purple: #6f619c;

// theme colors
$primary: $yellow;
$secondary: $blue;

// square off progress bars
$progress-border-radius: 0;

// Bootstrap functions and variables
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';

// bump up base font size
$font-size-base: 1.1rem;

// font weight
$bold: 600;

// some Bootstrap-esque additions
$font-size-md: ($font-size-base * 1.05);
$font-size-xl: ($font-size-base * 1.5);
$font-size-xxl: ($font-size-base * 2);

// for small checkboxes
$form-check-input-sm-gutter: 2.35rem !default;
$form-check-input-sm-margin-y: 0.3rem !default;
$form-check-input-sm-margin-x: 0.25rem !default;

// for large checkboxes
$form-check-input-lg-gutter: 3.55rem !default;
$form-check-input-lg-margin-y: 0.2rem !default;
$form-check-input-lg-margin-x: 0.25rem !default;

// full header height
$header-height: 50px;

// small header height
$small-header-height: 30px;

// width for main content
$site-width: 900px;

// error color
$error: $red;

// form input colors
$form-field-valid: $green;
$form-field-warning: $orange;
$form-field-error: $error;
