// pull in application variables
@import '_variables.scss';

.png-locations-map {
  height: 600px;
  width: 100%;
}

/* small screens */
@media screen and (max-width: 680px) {
  .png-locations-map {
    height: 400px;
  }
}

/* even smaller screens */
@media screen and (max-width: 576px) {
  .png-locations-map {
    height: 300px;
  }
}

/* tiny screens */
@media screen and (max-width: 375px) {
  .png-locations-map {
    height: 200px;
  }
}
