// pull in application variables
@import '_variables.scss';

.png-alerts {
  // make sure alerts sit at the top and on top of everything
  position: fixed;
  top: 5px;
  z-index: 2000;

  // don't take up the entire screen
  width: 80%;
  max-width: 600px;

  // center it
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.png-alerts .alert {
  // pack them closer together
  margin-bottom: 0.25rem;

  // make them pop
  border: 1px solid gray;
}
.png-alerts .alert .close {
  font-size: $font-size-xl;
}

.png-alerts .alert .close:focus {
  outline: none;
}
