// busy indicator container
.png-busy {
  // fixed to bottom of viewport
  width: 100%;
  position: fixed;
  margin-bottom: 0px;
  left: 0;
  bottom: 0;

  // look and feel
  height: 25px;

  // stay on top of everything save for modals
  z-index: 1800;
}

// busy indicator bar
.png-busy-bar {
  // fixed to bottom of viewport
  width: 100%;
  position: fixed;
  margin-bottom: 0px;
  left: 0;
  bottom: 0;

  // look and feel
  height: 25px;
  border: 0px solid rgba(245, 245, 245, 1);

  // can't interact with it
  cursor: not-allowed;

  // stay on top of everything save for modals
  z-index: 1800;
}

// a container overlay
#png-overlay {
  // sit on top of all page content save for the busy indicator
  position: fixed;
  z-index: 1900;

  // hidden by default
  display: none;

  // can't interact with it
  cursor: not-allowed;

  // cover the whole parent container
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  // black background with opacity
  background-color: rgba(0, 0, 0, 0.5);
}
