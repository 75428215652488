// variables
@import '_variables.scss';

.png-reservation-book-package-groups {
  /* should match the top padding for .col-form-label-lg */
  padding-top: calc(0.5rem + 1px);

  /* fill the space better */
  padding-left: 0px !important;
  padding-right: 0px !important;

  /* we require a minimum amount of space */
  min-width: 330px;
}
.png-reservation-book-package-group {
  margin-bottom: 6px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.png-reservation-book-package-group-name {
  font-size: 1.5rem;
}
.png-reservation-book-package-package-name {
  font-size: 1.2rem;
  border-bottom: 1px solid black;
  padding-top: 10px;
  padding-bottom: 4px;
  margin-bottom: 4px;
  display: flex;

  input[type='radio'] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;

    /* Remove most all native input styles */
    appearance: none;

    /* For iOS < 15 */
    background-color: var(--form-background);

    /* Not removed via appearance */
    margin: 0;
    margin-right: 4px;

    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 2px solid currentColor;
    border-radius: 50%;
    transform: translateY(0.1em);

    display: grid;
    place-content: center;

    &:before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);

      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
.png-reservation-book-package,
.png-reservation-book-package-selected {
  background-color: pink;
  margin-bottom: 6px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.png-reservation-book-package {
  background-color: #e9ecef;
}
.png-reservation-book-package:hover {
  background-color: $lightyellow;
}
.png-reservation-book-package-selected {
  background-color: $lightyellow;
  box-shadow: 0 0 2pt 1pt black;
}
.png-reservation-book-package-label {
  width: 100%;
}
.png-reservation-book-package-total {
  font-size: 1.2rem;
  font-weight: $bold;
}

.png-reservation-book-package-fpp {
  margin-top: 10px;
}
.png-reservation-book-package-fpp-days {
  font-size: 2.5rem;
  padding: 0 10px 0 10px;
}
.png-reservation-book-package-fpp-change {
  font-size: 0.8rem;
  margin-top: 0px;
  padding: 15px !important;
  min-width: 20px !important;
}

.png-reservation-book-package-breakdown {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
