/* test mode indicator */
.png-test-mode {
  /* make it stand out */
  font-size: 2rem;
  color: red;

  /* it's clickable */
  cursor: pointer;

  /* fixed to bottom right of viewport */
  position: fixed;
  right: 15px;
  bottom: 15px;

  /* stay on top of everything */
  z-index: 2000;
}
