// variables
@import '_variables.scss';

.png-reservation-book-services {
  /* should match the top padding for .col-form-label-lg */
  padding-top: calc(0.5rem + 1px);

  /* fill the space better */
  padding-left: 0px;
  padding-right: 0px;
}
.png-reservation-book-service,
.png-reservation-book-service-selected {
  background-color: #debf00;
  margin-bottom: 6px;
  border-radius: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.png-reservation-book-service {
  background-color: #e9ecef;
}
.png-reservation-book-service:hover {
  background-color: $lightyellow;
}
.png-reservation-book-service-selected {
  background-color: $lightyellow;
  box-shadow: 0 0 2pt 1pt black;
}

.png-reservation-book-service-notes {
  font-size: smaller;
  font-style: italic;
}
