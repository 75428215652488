.png-reservation-view-missing {
  font-size: 5rem;
}

.png-reservation-view {
  /* buttons */
  .btn {
    margin-top: 10px;
  }
}

/* mobile overrides */
@media screen and (max-width: 576px) {
  .png-reservation-view {
    /* buttons */
    .btn {
      min-width: 100px;
      padding: 15px 20px;
    }
  }
}

/* even smaller mobile overrides */
@media screen and (max-width: 425px) {
  .png-reservation-view {
    /* buttons */
    .btn {
      min-width: 80px;
      padding: 15px 10px;
    }
  }
}
