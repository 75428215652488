// variables
@import '_variables.scss';

.png-reservation-summary {
  /* look and feel */
  border: 1px solid black;
  border-radius: 0 6px 6px 6px;

  /* we pad elsewhere */
  padding: 0px;

  /* space them out */
  margin-bottom: 16px;
}

.png-reservation-summary-location {
  /* look and feel */
  border: 1px solid black;
  border-bottom: 0px;
  border-radius: 6px 6px 0 0;
  font-size: 0.9rem;

  /* shrink the width to hug the text */
  display: inline-block;

  /* breathing room */
  padding: 4px;
}
.png-reservation-summary-location a {
  /* look and feel */
  color: $black;
}

.png-reservation-summary-redeemed {
  background-color: darkgray !important;
}

.png-reservation-summary-canceled {
  background-color: $red !important;
}

.png-reservation-summary-missed {
  background-color: lightgray !important;
}

.png-reservation-summary-content {
  /* fill the space */
  width: 100%;

  /* breathing room */
  padding: 12px 0 12px 0;

  /* needs to sit below the edit/cancel buttons */
  z-index: 50;
}

.png-reservation-summary-content:hover {
  background-color: $lightyellow;
  border-radius: 0 6px 6px 6px;
  cursor: pointer;
}

.png-reservation-summary-content-item {
  /* no padding */
  padding: 0;
}

.png-reservation-summary-content-item-header {
  font-weight: $bold;
}

/* small screens */
@media screen and (max-width: 576px) {
  .png-reservation-summary-content-item {
    /* spacing while stacked */
    margin-bottom: 6px;
  }
}

/* action buttons */
.png-reservation-summary-actions {
  /* breathing room */
  padding: 12px;

  /* separator */
  border-left: 1px solid black;
}

/* edit/cancel */
.png-reservation-summary-edit,
.png-reservation-summary-cancel {
  /* make them stand out */
  font-size: 20px;

  /* clickable */
  cursor: pointer;
}

/* cancel */
.png-reservation-summary-cancel {
  /* this icon renders a little smaller than the edit icon */
  font-size: 24px;
}
