.png-fp-login {
  /* limit the width and center it */
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.png-fp-button-logout {
  margin-top: 0.6rem;
}
