// pull in application variables
@import '_variables.scss';

/* page header */
.png-header {
  /* we need a predictable height */
  height: $header-height;

  /* fill the available width */
  width: 100%;

  /* make sure it stays on top when not on mobile */
  z-index: 900;

  /* the header container will establish padding */
  padding-left: 0px;
  padding-right: 0px;

  /* fix it to the top when not on mobile */
  position: fixed;
  top: 0;
  overflow: hidden;

  /* animation magic for changes */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;

  /* login text */
  .png-login {
    font-variant: small-caps;
    font-weight: 500;
  }
}

/* small header */
.png-header-small {
  /* we need a predictable height */
  height: $small-header-height;
}

/* header container */
.png-header-container {
  /* match the content width */
  max-width: 900px;

  /* make sure content is positioned relative to this container */
  position: relative;

  /* pad off the edges */
  padding-left: 5px;
  padding-right: 5px;

  /* center it */
  margin-left: auto;
  margin-right: auto;
}

/* header name and logos */
.png-header-name-logos {
  display: flex;
}

/* header name */
.png-header-name {
  /* clickable */
  cursor: pointer;

  /* only one */
  background-repeat: no-repeat;

  /* size */
  width: 88px;
  height: $header-height;

  /* padding for logos */
  margin-left: 14px;
  margin-right: 14px;

  /* resize with the container */
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;

  /* animation magic for changes */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

/* header logos */
.png-header-logos {
  /* look and feel */
  margin-top: 5px;
  height: 40px;

  /* animation magic for changes */
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

/* small header logos */
.png-header-logos-small {
  /* look and feel */
  margin-top: 2px;
  height: 26px;

  /* hide them */
  opacity: 0;
}

/* small name */
.png-header-name-small {
  /* size */
  width: 52px;
  height: $small-header-height;
}

/* FPP icon */
.png-fpp-icon {
  /* offset from top */
  margin-top: 3px;

  /* look and feel */
  font-size: 2.2rem;
  text-align: right;

  /* no outline on click */
  outline: none;

  /* animation magic for changes */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

/* FPP icon, active */
.png-fpp-icon-active {
  /* look and feel */
  /* font-size: 1.5rem; */
  color: $white;
  filter: drop-shadow(0 0 4px black);
}

/* small FPP icon */
.png-fpp-icon-small {
  /* look and feel */
  font-size: 1.2rem;
}

/* side container */
.png-header-side {
  /* we want both sides to be the same width */
  min-width: 100px;
}

/* hamburger icon */
.png-hamburger-container {
  /* height should match that of .png-header */
  height: $header-height;

  /* allow sizing of bars */
  display: inline-block;

  /* clickable */
  cursor: pointer;
}
.png-hamburger-icon {
  /* vertically center the icon in the header: (header height - ((bar height * 2) + (bar margin * 2)) / 2 */
  margin-top: 11px;
  margin-bottom: 12px;
  transition: margin 0.4s;

  /* no outline on click */
  outline: none;
}
.png-hamburger-bar1,
.png-hamburger-bar2,
.png-hamburger-bar3 {
  /* bar size */
  width: 35px;
  height: 5px;

  /* look and feel */
  background-color: #333;
  border-radius: 2px;

  /* space between bars; note that the margins overlap, so this value is not doubled */
  margin: 6px 0;

  /* animation magic for changes */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}
.active .png-hamburger-bar1,
.active .png-hamburger-bar2,
.active .png-hamburger-bar3 {
  /* hard corner on X */
  border-radius: 0px;
}
.active .png-hamburger-bar1 {
  /* hide it */
  opacity: 0;
}
.active .png-hamburger-bar2 {
  /* hide it */
  opacity: 0;
}
.active .png-hamburger-bar3 {
  /* hide it */
  opacity: 0;
}

/* small hamburger icon */
.png-hamburger-container-small {
  /* height should match that of .png-header */
  height: $small-header-height;
}
.png-hamburger-icon-small {
  /* vertically center the icon in the header: ((header height - ((bar height * 3) + (bar margin * 2)) / 2) */
  margin-top: 6px;
  margin-bottom: 7px;
}
.png-hamburger-bar1-small,
.png-hamburger-bar2-small,
.png-hamburger-bar3-small {
  /* bar size */
  width: 25px;
  height: 3px;

  /* space between bars; note that the margins overlap, so this value is not doubled */
  margin: 4px 0;
}

/* narrow browser (usually mobile) overrides; these should match the *-small settings */
@media screen and (max-width: 680px) {
  /* page header */
  .png-header {
    /* do not fix to the top in mobile */
    position: relative;

    /* make it smaller */
    height: $small-header-height;
  }

  /* header name */
  .png-header-name {
    /* size */
    width: 52px;
    height: $small-header-height;
  }

  /* side container */
  .png-header-side {
    /* we want both sides to be the same width */
    min-width: 50px;
  }

  /* header logos */
  .png-header-logos {
    display: none;
  }

  /* FPP icon */
  .png-fpp-icon {
    /* look and feel */
    font-size: 1.2rem;
  }

  /* hamburger icon */
  .png-hamburger-container {
    /* height should match that of .png-header */
    height: $small-header-height;
  }
  .png-hamburger-icon {
    /* vertically center the icon in the header: ((header height - ((bar height * 3) + (bar margin * 2)) / 2) */
    margin-top: 6px;
    margin-bottom: 7px;
  }
  .png-hamburger-bar1,
  .png-hamburger-bar2,
  .png-hamburger-bar3 {
    /* bar size */
    width: 25px;
    height: 3px;

    /* space between bars; note that the margins overlap, so this value is not doubled */
    margin: 4px 0;
  }
}
