// pull in application variables
@import '_variables.scss';

.png-retry-message {
  /* should match the png-content-loading settings */
  margin-top: 20px;
  margin-bottom: 20px;
}

.png-retry-message-link {
  cursor: pointer;
  font-weight: $bold;
}
