.png-confirm-dialog {
  border: 1px solid black;
  border-radius: 6px;
  padding: 15px;
  background: white;
}

.react-confirm-alert {
  max-width: 600px;
}

.react-confirm-alert-overlay {
  // on top of most things
  z-index: 1900;

  // black background with opacity
  background-color: rgba(0, 0, 0, 0.5);

  // no margins
  margin: 0;
}

// keep the scrollbar state when launching modal
body.react-confirm-alert-body-element {
  overflow-y: scroll;
}
