// Live Agent button
div[title='Contact us button'],
div[title='Live chat button'] {
  // container
  div:first-child {
    left: auto !important;
    right: 10px !important;
    bottom: 30px !important;
    z-index: 1000 !important;

    // bubble
    div[style*='font-weight:bold'] {
      -webkit-border-radius: 15px !important;
      border-radius: 15px !important;
    }
  }
}

/* mobile overrides; these should match the *-small settings */
@media screen and (max-width: 425px) {
  div[title='Contact us button'],
  div[title='Live chat button'] {
    div:first-child {
      right: 0px !important;
    }
  }
}
